.main{
    width: 100% !important;
    max-width: 1080px  !important;
}

.box-inner-data {
    height: 650px !important;
    grid-template-rows: 50px;
}

.box-inner-data .btn {
    height: 50px;
}

h3.styled {
    font-size: 80px;
    font-weight: 800;
    line-height: 54px;
    color: #fff;
    text-shadow: 0px 7px 1px rgba(0, 0, 0, 0.4), 0px 2px 5px rgba(0, 0, 0, 0.4), 0px 3px 1px rgba(0, 0, 0, 0.1);
    -webkit-text-stroke: 3px #59a1fe;
    text-transform: uppercase;
    text-align: center;
}