* {
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  body {
    font-family: "Gotham";
  }

  /* ---- */
  .container{
    max-width: 700px !important;
    padding: 0 10px;
    margin: auto;
}
.main{
  width: 100%;
  min-height: 100vh;
  max-width: 768px;
  margin: auto;
  overflow: hidden; 
  background-image: url('../images/bgimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px;
  z-index: 1;
  position: relative;
}
.outer-border{    
    border: 7px solid rgb(255, 255, 255,0.6);
    border-radius: 20px;
    padding: 30px;
}
.inner-content{
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 30px;
    box-shadow: 10px 20px 50px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}
.inner-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:  40px 0;
    border-radius: 30px 30px 0 0 ;
       background-color: #134adc;
       box-shadow: inset 0 20px 40px #87a5f8;
       position: relative;
       z-index: 2;
}
.left-after{
    position: absolute;
    content: "";
    background-color: #2f83c4;
    box-shadow: inset 0 49px 61px rgba(0, 0, 0, 0.2);
    height:  29px;
    width: 49px;
    border-radius: 50px 50px 0 0;
    bottom: -12px;
    left: -16px;
    transform: rotate(90deg);
    z-index: 2;
}
.right-before{
    position: absolute;
    content: "";
    background-color: #3c8ac6;
    box-shadow:inset 0 21px 45px rgb(0 0 0 / 40%);
    height:  29px;
    width: 49px;
    border-radius: 50px 50px 0 0;
    bottom: -12px;
    right: -16px;
    transform: rotate(-90deg);
    z-index: 4;
}

/* .right-before:after{
    content: '';
    position: absolute;
    top: 14px;
    right: -10px;
    background-color: #5C83EE;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 1;
} */
.inner-logo img{
   width: 100%;
   max-width: 260px;
}
.main-content{
    padding: 50px 40px 60px;  
    background-color: white;
    border-radius:  0 0 30px 30px;
    position: relative;
    z-index: 1;
    box-shadow:     inset -0px -13px 8px 0 #618ba9, inset 8px 8px 8px 0 #fff, -8px -8px 24px 0 #fff, 8px 8px 50px 0 #73add6;
}
.main-box1{
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 12px 1px #91aaf0;
}
.mt-box{
    margin-top: 50px;
}
.main-box1 .top-btn{
    border-radius: 0 0 20px 20px;
    color: white;
    padding: 7px 50px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    margin-top: -10px;
    margin-bottom: 10px;
    position: relative;
}
.main-box1 .ready-btn.top-btn:after{
    border-top: 10px solid #104df5;
}
.main-box1 .processing-btn.top-btn:after{
    border-top: 10px solid #f68f21;
}
.main-box1 .top-btn:after{
    left: -11px;
    top: -1px;
    border-right: 10px solid transparent;
    border-left:10px solid transparent;
    border-bottom: 10px solid transparent;
    transform: rotate(-45deg);
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
} 
.main-box1 .ready-btn.top-btn:before{
    border-top: 10px solid #104df5;
}
.main-box1 .processing-btn.top-btn:before{
    border-top: 10px solid #f68f21;
}
.main-box1 .top-btn:before{
    right: -11px;
    top: -1px;
    border-right: 10px solid transparent;
    border-left:10px solid transparent;
    border-bottom: 10px solid transparent;
    transform: rotate(45deg);
    content: "";
    width: 10px;
    height: 13px;
    position: absolute;
    border-radius: 50%;
}

.ready-btn{
    background: rgb(8,130,251);
background: linear-gradient(187deg, rgba(8,130,251,1) 13%, rgba(19,74,220,1) 59%);
    /* 134adc = niche
0882fb = upper */
}
.processing-btn{
    background: rgb(246,143,33);
    background: linear-gradient(187deg, rgba(246,143,33,1) 13%, rgba(251,182,21,1) 59%);
    /* f68f21 =upper
fbb615 = nichhe */
}
.box-inner-data{
    padding: 10px 14px 20px 14px;
    display: grid;
    height: 300px;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-right: 3px;
}

.box-inner-data .btn{
    border-radius: 10px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 14px;
 
}
.ready-inner-btn .btn{
    background-color: #cce4ff;
    color: #2443d3;
}
.processing-inner-btn .btn{
    background-color: #ffeec7;
    color: #2443d3;
}
.bg1{
    height: 50px !important;
    border-radius: 50%;
    box-shadow: 20px 209px 500px 200px #83caee;
    opacity: 100%;
    -webkit-appearance: none;
    position: absolute;
    bottom: 44%;
    right: 190px;
    width: 0px !important;
    z-index: -1;
}
.bg2{
    height: 100px !important;
    border-radius: 50%;
    box-shadow:55px 41px 371px 200px #bfdff0;
    opacity: 100%;
    -webkit-appearance: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 0px !important;
    z-index: -1;
}
/* --scrollbar-- */
::-webkit-scrollbar {
    width: 12px;  
  }
  ::-webkit-scrollbar-track {
    background: #87a5f8; 
    border-radius: 10px;
    margin: 70px 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff; 
    border-radius: 10px;
    border: 3px solid #87a5f8;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  }
/* ---responsive-- */
@media (max-width:670px){
    .container{
        max-width: 800px !important;
        padding: 0 20px;
    }
}
@media (max-width:575px){
  .main{
    padding: 14px;
  }
  .outer-border{
    padding: 14px;
  }
  .inner-logo img{
    max-width: 140px;
  }

  .main-content{
    padding: 50px 20px 60px;
  }
  .box-inner-data{
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
  }
  .left-after ,.right-before{
    width:47px ;
    bottom: -13px;
  }
  .mt-box{
    margin-top: 35px;
  }
  .main-box1 .top-btn{
    font-size: 20px;
    line-height: 22px;
  }
  .inner-logo{
    padding: 35px 0;
  }
}
@media (max-width:425px){
    .box-inner-data{
        grid-template-columns : 1fr 1fr 1fr 1fr;
      }
      .main-box1 .top-btn{
        padding: 5px 30px;
      }
      .main-content{
        padding: 50px 20px 50px;
      }
}
@media (max-width:390px){
    .box-inner-data{
      padding:  10px 8px 20px 8px; 
      gap: 8px;
    }
}
@media (max-width:375px){
    .box-inner-data{
        padding: 10px 10px 20px 10px;
    }
    .main-box1 .top-btn{
        padding: 5px 20px;
      }
      .main-content{
        padding: 50px 10px 40px;
      }
}
@media (max-width:320px){
    .box-inner-data{
        grid-template-columns : 1fr 1fr 1fr;
      }
      .main-box1 .top-btn{
        font-size: 12px;
        line-height: 14px;
      }
      .inner-logo img {
        max-width: 106px;
    }
    .left-after, .right-before {
        width: 34px;
    }
    .main-content {
        padding: 38px 10px 28px;
    }
    .inner-logo{
        padding: 35px 0;
    }
}
