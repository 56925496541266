@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-BookItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-UltraItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-XLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-XLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/GothamBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/GothamBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
